.ModalSelectWokSouce
{
    position: absolute;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: clamp(280px, 85vw, 600px);
    aspect-ratio: 10/11;
    box-shadow: 24;
    border-radius: 20px;
    overflow: hidden;
    background-color: var(--paper-color);
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    box-sizing: border-box;
    align-items: center;

    label span
    {
        font-size: clamp(20px, 3vw, 26px) !important; 
    }

    h3
    {
        margin-top: 50px;
        font-size: clamp(24px, 3vw, 30px); 
    }

    &>button
    {
        width: 90%;
    }


    @media screen and (min-width: 600px)
    {
        aspect-ratio: 4/3;
    }
    @media screen and (min-width: 1000px)
    {
        aspect-ratio: 3/3;
    }

    @media screen and (max-height: 700px)
    {
        min-width: 330px;
        width: 90vh;
        aspect-ratio: 4/3;
    }

}

.content-modal_promotion
{
    height: 100%;
    padding: 4%;
    width: 90%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.close-modal_product
{
    position: absolute !important;
    right: 0;
    background-color: rgba($color: #fff, $alpha: 0.4) !important;
    color: var(--text-color-primary) !important;
    width: auto !important;
}

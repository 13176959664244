.Promotion
{
    cursor: pointer;

    img
    {
        height: 100%;
        width: 100%;
        object-fit:cover;
    }
}
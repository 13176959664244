@import './theme/theme.scss'; 


body
{
    background-color: var(--background-color);
}

*
{
    margin: 0;
    padding: 0;
    font-family: 'DisplayPro';
}

@font-face {
    font-family: "DisplayPro";
    src: url("./assets/font/DisplayPro.ttf");
}

.pageMarginContainer
{
    margin: 2vh 0 10vh;
    width: clamp(300px, 85vw, 1100px);


    h1
    {
        margin-top: 10px;
        text-align: center;
    }
}

.tinyContainer
{
    width: clamp(300px, 85vw, 800px);;
}

.App
{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    align-items: center;
}

.MuiButton-root
{
    font-size: clamp(16px, 3vw, 20px) !important;
}
.Basket {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;

    & > h3 {
        width: fit-content;
        text-align: center;
        border: 2px solid var(--primary-color);
        border-radius: 15px;
        padding: 8px 15px; // Добавил горизонтальные отступы
    }

    hr {
        width: 100%;
        color: var(--paper-color);
    }

    .order-total {
        font-size: 24px;
        font-weight: bold;
        margin-top: 20px;
    }

    .order-buttons {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;

        .order-basket {
            width: 48%;
            font-size: 18px !important;
            font-weight: bold !important;
            height: 45px;
        }
    }
}

.empty-basket {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    width: 100%;

    h2 {
        margin-bottom: 20px;
    }
}

.order-basket {
    margin-top: 50px !important;
    width: 100%;
    display: flex;
    font-size: 22px !important;
    font-weight: bold !important;
    height: 45px;
}

.BasketProduct
{
    width: 100%;
    height: 18vh;
    min-height: 100px;
    display: flex;
    border: 1px solid rgb(226, 226, 226);
    border-radius: 12px;
    align-items: center;
    background-color: var(--paper-color);
    overflow: hidden;
    padding: 6px;
    gap: 10px;
    box-sizing: border-box;

    img
    {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
    }
    >.open_modal-basket_product
    {
        height: 100%;
        width: 90%;
    }
    .open_modal-basket_product
    {
        text-align: left;
        font: inherit;
        color: inherit;
        background-color: transparent;
        cursor: pointer;
        border: none;
    }

    @media screen and (min-width: 768px)
    {
        >.open_modal-basket_product
        {
            width: 50%;
        }
    }

    @media screen and (min-width: 768px)
    {
       min-height: 180px;
       height: 20vh;
    }



    @media screen and (max-height: 680px) {
        height: 34vh;
        min-height: 160px;
    }

}

.content-basket_product
{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 92%;
    justify-content: space-between;
}

.title_desc-basket_product
{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    p
    {
        overflow: hidden;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        box-orient: vertical;
        font-size: clamp(14px, 2.4vw, 18px); 
        color: var(--text-color-secondary);
    }

    h3
    {
        margin-top: 2px;
        font-size: clamp(20px, 2.4vw, 24px); 
        line-height: clamp(20px, 2.4vw, 24px); 
        overflow: hidden;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        line-clamp: 1;
        box-orient: vertical;
        color: var(--text-color-secondary);
    }
}


.BasketProduct {
    transition: opacity 0.5s ease, transform 0.5s ease;
    opacity: 1;
    transform: translateX(0);
}

.BasketProduct.removing {
    opacity: 0;
    transform: translateX(-100%);
}

// .count_button-product
// {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
    
//     button
//     {
//         min-width: 0 !important;
//         padding: 0px !important;
//     }

//     :nth-child(1), :nth-child(2)
//     {
//         // width: 25% !important;
//     }

//     span
//     {
//         text-align: center;
//         font-size: clamp(18px, 3vw, 26px); 
//         width: 32px;
//     }
// }

// .plus_minus-product
// {

    
//     svg
//     {
//         font-size: clamp(20px, 10vw, 40px); 
//     }
// }
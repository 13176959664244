.Footer
{
    background-color: var(--footer-background);   
    display: flex;
    flex-direction: column;
    padding: 10px;
    color: var(--text-color-secondary);
    font-size: clamp(16px, 2.5vw, 24px); 
    width: calc(100vw - 20px);
}

.section-footer
{
    gap: 10px;
    margin-top: 10px;

    hr
    {
        margin-top: 10px;
        width: 100%;
        border: none;
        border-top: 1px solid var(--hr-color);
    }

    h3
    {
        font-size: clamp(18px, 3vw, 27px); 
        white-space: nowrap;
        text-align: center;
        width: 100%;
    }    
}

.main-footer
{
    display: flex;
    justify-content: space-between;
}

.menu-footer
{
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: space-evenly;
    align-items:center;

    a, a:visited, a:active, a:focus
    {
        color: var(--text-color-secondary);
        outline: none;
        border: none;
        text-decoration: none;
        transition: all 0.2s;
        &:hover
        {
            transition: all 0.2s;
            color: var(--primary-color);
        }
    }
    a
    {
        width: 33%;
        text-align: center;
    }
}

.logo-footer
{
    display: flex;
    flex-direction: column;
    align-items: start;

    img
    {
        height: clamp(60px, 18vw, 120px);   
    }

    @media screen and (min-width: 768px)
    {
        img
        {
            height: 170px;
            max-height: 170px;
            width: auto;
        }
    }


}

.contacts-footer
{
    // width: 10%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 5px;

    button
    {
        padding: 0px !important;
        min-width: 0px !important;

        svg
        {
            height: clamp(24px, 4vw, 40px); 
            width: clamp(24px, 4vw, 40px); 
        }
    }
}

.phone-footer
{
    display: flex;
    flex-direction: column;
}

.info-footer
{
    span
    {
        font-size: clamp(18px, 3vw, 27px); 
    }
    p
    {
        margin-top: 7px;
    }
}
.OrderDataInput .MuiTextField-root .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline
{
    border-color: var(--secondary-color);
}

.OrderDataInput .MuiTextField-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline
{
    border-color: var(--secondary-color);
}

.OrderDataInput .MuiInputLabel-root.Mui-focused
{
    color: var(--text-color);
}


.OrderDataInput
{
    display: flex;
    width: clamp(200px, 100%, 400px);;
    flex-direction: column;
    gap: 15px;

    input
    {
        font-size: clamp(16px, 2.4vw, 22px); 
    }
}

.About
{
    span
    {
        color: var(--primary-color);
    }
    h1
    {
        text-align: center;
        margin-bottom: 20px;
    }
    h3
    {
        margin-top: 18px;
        font-size: clamp(18px, 3vw, 24px); 
    }
    p
    {
        font-size: clamp(16px, 3vw, 22px); 
    }
    li
    {
        font-size: clamp(16px, 3vw, 22px); 
        margin-bottom: 5px;
    }
    ul
    {
        margin-bottom: 18px;
    }
}
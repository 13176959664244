.Contacts
{
    gap: 20px;
    display: flex;
    flex-direction: column;

    h1
    {
        margin-bottom: 20px;
    }

    .map-delivery
    {
        width: 100%;
        height: 400px;
    }

    h3
    {
        font-size: clamp(18px, 3vw, 24px); 
        font-weight: normal;
        span
        {
            font-weight: bold;
        }
    }
}
.Product {
    width: 48%;
    min-height: 300px;
    height: 35vh;
    max-height: 50vh;
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(226, 226, 226);
    align-items: center;
    background-color: var(--paper-color);
    box-sizing: border-box;
    flex-grow: 0;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    > .open_modal-product {
        height: 50%;
    }

    .open_modal-product {
        text-align: left;
        font: inherit;
        color: inherit;
        background-color: transparent;
        cursor: pointer;
        border: none;
        width: 100%;
    }

    @media screen and (min-width: 768px) {
        width: 31%;
        min-height: 400px;
    }

    @media screen and (min-width: 1200px) {
        width: 22%;
    }

    @media screen and (max-height: 680px) {
        max-height: 85vh;
        min-height: 350px;
    }
}

.content-product
{
    display: flex;
    flex-direction: column;
    height: 48%;
    padding: 1% 0;
    width: 92%;
    justify-content: space-between;
}

.title_desc-product
{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    p
    {
        overflow: hidden;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        box-orient: vertical;
        font-size: clamp(14px, 2.4vw, 18px); 
        color: var(--text-color-secondary);
    }

    h3
    {
        margin-top: 2px;
        font-size: clamp(20px, 2.4vw, 24px); 
        line-height: clamp(20px, 2.4vw, 24px); 
        overflow: hidden;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        box-orient: vertical;
        color: var(--text-color-secondary);
    }
}

.price_weight_button-product
{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
}

.price_weight-product
{
    display: flex;
    justify-content: space-between;
    font-size: clamp(18px, 2.4vw, 22px); 
}

.count_button-product
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    button
    {
        min-width: 0 !important;
        padding: 0px !important;
        font-size: clamp(24px, 4vw, 35px); 
        align-items: center;
        display: flex;
        width: clamp(30px, 5vw, 55px);
        
        span
        {
            font-size: clamp(24px, 4vw, 35px); 
            width: clamp(30px, 5vw, 55px);
        }
    }

    .add_button-product
    {
        width: 100%;
        span
        {
            width: 100%;
        }
    }

    span
    {
        text-align: center;
        font-size: clamp(18px, 3vw, 26px); 
        width: 32px;
    }
}

.plus_minus-product
{

    svg
    {
        font-size: clamp(24px, 4vw, 35px); 
    }
}
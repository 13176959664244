.ProductsContainer {
    width: 100%;

    h2 {
        font-size: 30px;
    }

    h2::first-letter {
        text-transform: uppercase;
    }
}

.prods-product_container {
    display: flex;
    flex-wrap: wrap;
    gap: 3.5%;
    row-gap: clamp(10px, 3vw, 25px);
    justify-content: flex-start; /* Элементы выравниваются по левому краю */
    margin-top: 2px;
}
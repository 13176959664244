.ChipMenu
{
    display: flex;
    overflow-x: scroll;
    gap: clamp(10px, 3vw, 25px); 
    padding-bottom: 10px;
    

    button
    {
        box-shadow: none;
        border-radius: 20px;
        padding: 3px 15px !important;
        font-size: clamp(18px, 3vw, 24px); 
        width: fit-content !important;
        text-transform: lowercase;
        min-width:auto !important;
    }

    @media screen and (min-width: 768px)
    {
        justify-content: center;
    }
}

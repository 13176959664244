.ModalProduct
{
    position: absolute;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: clamp(280px, 85vw, 600px);
    aspect-ratio: 10/11;
    box-shadow: 24;
    border-radius: 20px;
    overflow: hidden;
    background-color: var(--paper-color);
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    box-sizing: border-box;

    img
    {
        width: 100%;
        height: 45%;
        object-fit:cover ;
    }

    @media screen and (min-width: 600px)
    {
        aspect-ratio: 4/3;
    }
    @media screen and (min-width: 1000px)
    {
        aspect-ratio: 3/3;
    }

    @media screen and (max-height: 700px)
    {
        min-width: 300px;
        width: 90vh;
        aspect-ratio: 4/3;
    }

}

.content-modal_product
{
    display: flex;
    flex-direction: column;
    height: 53%;
    padding: 1% 0;
    width: 85%;
    justify-content: space-between;
    align-self: center;
}

.title_desc-modal_product
{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    p
    {
        overflow: hidden;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        line-clamp: 4;
        box-orient: vertical;
        font-size: 17px;
        color: var(--text-color-secondary);
    }

    h3
    {
        margin-top: 10px;
        font-size: 24px;
        line-height: 24px;
        overflow: hidden;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        box-orient: vertical;
        color: var(--text-color-secondary);
    }
}

.price_weight_button-modal_product
{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
    margin-bottom: 10px;
}

.price_weight-modal_product
{
    display: flex;
    justify-content: space-between;
    font-size: 20px;
}

.count_button-modal_product
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    button
    {
        min-width: 0 !important;
        padding: 0px !important;
        width: clamp(32px, 5vw, 58px);

        span
        {
            font-size: clamp(26px, 4vw, 38px); 
            width: clamp(32px, 5vw, 58px);
        }
    }

    span
    {
        text-align: center;
        font-size: clamp(24px, 3vw, 30px); 
        width: 32px;
    }




    .add_button-product
    {
        width: 100%;
        span
        {
            width: 100%;
        }
    }
}

.plus_minus-modal_product
{

    svg
    {
        font-size: clamp(26px, 4vw, 38px); 
    }
}

.close-modal_product
{
    position: absolute !important;
    right: 0;
    background-color: rgba($color: #fff, $alpha: 0.4) !important;
    color: var(--text-color-primary) !important;
}
#Header
{
    height: 8vh;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    width: 96%;
    padding: 1vh 2%;
    position:fixed;
    top: 0;
    z-index: 5000;
    @media screen and (max-height: 680px) {
        height: 12vh;
    }
}

#Header .logo-header
{
    height: 100%;

    img
    {
        height: 100%;
    }
}


#Header nav
{   
    display: flex;
    justify-content: space-between;
    align-items: center;   
    height: 100%;
    gap: 10px;
    width: 100%;

    svg{
        font-size: 32px;
    }

    .menu-item-header
    {
        height: 100%;
        display: flex;
        align-items: center;
    }

    .basket-header
    {
        span
        {
            font-size: 18px;
        }
    }

    .menu-header
    {
        button
        {
            z-index: 1300;
        }
    }
}

.menu_basket-header
{
    display: flex;
    gap: 10px;
}
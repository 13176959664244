.SideMenu
{
    width: 100vw;
    height: 90vh;
    display: flex;
    padding-top: 10vh;

    ul
    {
        width: 100%;
        display: flex;
        flex-direction: column;
        font-size: 20px;
    }
}

.horizontal-side_menu
{
    padding: 0 30px;
    width: 100%;
    height: 100%;
    ul
    {
        flex-direction: row;
        font-size: clamp(19px, 2vw, 24px); 
        padding: 0;

        li
        {
            padding: 0;
        }

        a
        {
            justify-content: center;
            height: 100%;
        }
    }
}

.MuiPaper-root .MuiPaper-elevation .MuiPaper-elevation16 .MuiDrawer-paper .MuiDrawer-paperAnchorRight
{
    width: 100%;
}
.Delivery
{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    height: 100%;

    h1
    {
        text-align: center;
        margin-bottom: 15px;
    }

    h2
    {
        font-size: 25px;
    }
    h3
    {
        font-size: 22px;
    }

}

.map-delivery
{
    width: 100%;
    height: 200%;
}

.accent-delivery
{
    color: var(--primary-color);
}